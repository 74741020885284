import axios from "axios";
import Cookies from "js-cookie";
import PubSub from "@/lib/Pub";
import { ValidateEmailAndCode, UpdateMmByEmail } from "@/services/config";
export default {
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请输入密码"));
			} else {
				if (this.ruleForm2.checkPass !== "") {
					this.$refs.ruleForm2.validateField("checkPass");
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === "") {
				callback(new Error("请再次输入密码"));
			} else if (value !== this.ruleForm2.pass) {
				callback(new Error("两次输入密码不一致!"));
			} else {
				callback();
			}
		};
		return {
			validateCode: "",
			validateText: "获取验证码",
			countDown: 10,
			isCountDown: true,
			nextPage: true,
			dynamicValidateForm: {
				domains: "",
				email: ""
			},
			ruleForm2: {
				pass: "",
				checkPass: "",
				age: ""
			},
			rules2: {
				pass: [{ validator: validatePass, trigger: "blur" }],
				checkPass: [{ validator: validatePass2, trigger: "blur" }]
			}
		};
	},
	created() {
		this.tokenVal = Cookies.get("token");
	},
	methods: {
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					if (this.dynamicValidateForm.domains == this.validateCode) {
						this.nextPage = false;
					} else {
						this.$message("验证码不正确！");
					}
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		submitFormPass(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.axios
						.post(UpdateMmByEmail, {
							email: this.dynamicValidateForm.email,
							password: this.ruleForm2.pass
						})
						.then(res => {
							if (res.data.code == 200) {
								this.$message('密码重置成功！');
								// 返回登录页面
								setTimeout(() => {
									Cookies.set("token", "");
									this.$router.push({ path: "/" })
									PubSub.publish("loginOutEvent");
								}, 2000);
							}
						});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		removeDomain(item) {
			if (!this.isCountDown) {
				return;
			}
			this.axios
				.post(ValidateEmailAndCode, {
					email: item
				})
				.then(res => {
					if (res.data.code == 200) {
						if (res.data.data.code == 402) {
							this.$message(res.data.data.info);
							return;
						} else if (res.data.data.code == 200) {
							this.isCountDown = false;
							this.validateCode = res.data.data.vcode;
							this.validateText = '验证码已发送';
						}
					}
				});
		},
		addDomain() {
			this.dynamicValidateForm.domains.push({
				value: "",
				key: Date.now()
			});
		}
	},
	components: {
		axios
	}
};
